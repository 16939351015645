import React, { useState } from 'react'

type Props = {
  technicalSkillsJson: any
  minCount: number
}

const MentorTechnicalSkills = ({
  technicalSkillsJson,
  minCount
}: Props) => {

  const [showSkills, setShowSkills] = useState(minCount);
  return (
    <div className='flex flex-wrap gap-3'>
      {technicalSkillsJson?.slice(0, showSkills)?.map((item: any, index: number) => (
        <p
          key={index}
          className="text-white text-xs font-medium py-2 px-3 rounded-full bg-[#222]"
        >
          {item.name}
        </p>
      ))}
      {technicalSkillsJson.length > minCount &&
        (showSkills <= minCount ? (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowSkills(technicalSkillsJson.length);
            }}
          >
            +{technicalSkillsJson.length - minCount} More
          </p>
        ) : (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowSkills(minCount);
            }}
          >
            Show Less
          </p>
        ))}
    </div>
  )
}

export default MentorTechnicalSkills